


























import { Vue, Provide, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/mine'
import { Toast } from 'vant';
@Component({
  components: {
    Nav,
  },
})
export default class SystemMessage extends Vue {
  storeId=""
  @Provide() storeImg = require("@/assets/img/store.jpg");
  @Provide() listData = [];
  @Provide() show = false;
  created(){
    this.getCollage()
  }
  async submit() {
    const res = await serve.delCollage(this.storeId)
    if(res.data.code === 0 ){
      Toast.success('取消收藏')
      this.getCollage()
    }else{
      Toast.fail(res.data.message)
    }
      this.show = false
  }
  async getCollage(){
    const  res = await serve.getCollageList()
    if(res.data.code === 0 ){
      this.listData = res.data.data
    }
  }
}
